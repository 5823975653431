import {
  Button,
  DatePicker,
  Flex,
  Form,
  Input,
  Modal,
  Radio,
  Select,
  notification,
  Checkbox
} from "antd";
import { useForm } from "antd/es/form/Form";
import dayjs from "dayjs";
import React, { useState } from "react";
import FileModal from "./FileModal";
import SeekSolutionApi from "../../../utils/SeekSolutionApi";

import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

function formatDate(dateString) {
  const date = new Date(dateString);
  const options = { year: 'numeric', month: 'short', day: '2-digit' };
  return date.toLocaleDateString('en-US', options);
}

function truncateText(texts, maxLength) {
  let text = texts.split('\n').filter(line => line.trim() !== '').join('\n')
  if (text.length > maxLength) {
      return `${text.substring(0, maxLength)}...`;
  }
  return text;
}

const TaskModal = ({
  modalTitle,
  modalOpen,
  setOpenModal,
  handleForm,
  commentMode,
  task_detail,
  loading,
  isDescriptionPresent, // Add this prop
  initialiseCompanyTasks,
  ...props
}) => {
  const [form] = useForm();

  const [fileModalVisible, setFileModalVisible] = useState(false)
  const [taskId, setTaskId] = useState("")
  const [commentBy, setCommentBy] = useState('');
  const [commentText, setCommentText] = useState('');
  const [commentDate, setCommentDate] = useState('');
  const [api, contextHolder] = notification.useNotification();

  const [description, setDescription] = useState('');


  const openNotification = (messagedata) => {
    api.open({
        message: 'Alert',
        description: messagedata,
        placement: "top",
        duration: 2,
    });
};

  
  const deleteTask = async () => {
    try {
      await SeekSolutionApi.Tasks.delete(task_detail?.id);
      openNotification("Task deleted successfully!")
      console.log("Task deleted successfully!");
      initialiseCompanyTasks()
    } catch (error) {
      console.error("Error deleting task:", error);
    }
  }
  const handleEmails = async () => {
    try {
      const values = form.getFieldsValue();
      if (values.title && values.priority && values.due_date && values.description && values.assigned_to) {
        const emailData = {
          id: modalOpen.id,
          ...values,
        };
        await SeekSolutionApi.Tasks.email(emailData);
        openNotification("Email sent successfully!")
        console.log("Email sent successfully!");
      } else {
        openNotification("Please complete the task details before sending an email.")
        console.log("Please complete the task details before sending an email.");
      }
    } catch (error) {
      console.error("Error sending email:", error);
    }
  };


  const initialiseComments = async () => {
    try {
        // setDataLoading(true)
        const apiRes = await SeekSolutionApi.Comments.list(task_detail?.id)
        let commentData = apiRes
        if (commentData.length >0){
          
          setCommentBy(commentData[commentData.length - 1].name)
          setCommentDate(formatDate(commentData[commentData.length - 1].created_date))
          if(commentData[commentData.length - 1]?.email_reply){
            let textfilter = truncateText(commentData[commentData.length - 1].comment, 30)
            setCommentText(textfilter)
          }else{
            setCommentText(commentData[commentData.length - 1].comment)
          }
        }
    } catch (error) {
        console.log("initialiseComments Error", error);
    } finally {
    }
}
if (task_detail?.id){
    initialiseComments()
}

  //convert object data to format for setting fields in antd form
  function objectToArray(obj) {
    return Object.entries(obj).map(([key, value]) => ({ name: key, value }));
  }

  const onFile = () => {
    setTaskId(modalOpen.id)
    setFileModalVisible(true)
    setOpenModal()
  }

  React.useEffect(() => {
    if (modalOpen) {
      //clone data
      let payload = JSON.parse(JSON.stringify(modalOpen));

      //convert date string to date object
      if (payload.due_date) {
        payload.due_date = dayjs(payload.due_date);
      }
        
      //format data for settings fields
      payload = objectToArray(payload);

      form.setFields(payload);
    }
    // eslint-disable-next-line
  }, [modalOpen]);

  return (
    <>{contextHolder}
    <Modal
      title={modalTitle}
      footer={false}
      open={modalOpen != null}
      onCancel={() => setOpenModal(false)}
      width={1000}
    >
      <Form
        form={form}
        name="basic"
        initialValues={{
          id: 1,
          assigned_to_email: "hammim.dev@seeksolution.in",
          company_name: "Dummy Company",
          created_by_first_name: null,
          created_by_last_name: null,
          assigned_to_first_name: "Hammim",
          assigned_to_last_name: "Dev",
          created_by_email: null,
          title: "qwerty",
          description: "fghj,",
          priority: "Medium",
          status: "In Progress",
          created_date: "2024-02-07T17:17:58.851647Z",
          updated_date: "2024-02-07T18:18:35.509268Z",
          assigned_to: 5,
          company: 113,
          created_by: null,
          frequency: "Daily",
          category:"Others",
          staff_only_view: false,
        }}
        autoComplete="off"
        onFinish={(values) => {
          let payload = JSON.parse(JSON.stringify({ ...modalOpen, ...values }));

          payload.due_date  = values.due_date;
          
          handleForm(payload);
        }}
        // size="small"

        labelCol={{ span: 4 }}
        // layout="vertical"
      >
        <Form.Item
          label="Title"
          name="title"
          rules={[{ required: true, message: "Please input your title!" }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Category"
          name="category"
          rules={[{ required: true, message: 'Please input your category!' }]}
          >
            <Radio.Group className="radio-inline">
                <Radio.Button value="SLCS Secretarial">SLCS Secretarial</Radio.Button>
                <Radio.Button value="SLCS Accounting">SLCS Accounting</Radio.Button>
                <Radio.Button value="Others">Others</Radio.Button>
                <Radio.Button value="Sales Pipeline">Sales Pipeline</Radio.Button>
            </Radio.Group>
        </Form.Item>

        <Form.Item
          label="Priority"
          name="priority"
          rules={[{ required: true, message: "Please select task priority!" }]}
        >
          <Radio.Group>
            <Radio.Button value="Low">Low</Radio.Button>
            <Radio.Button value="Medium">Medium</Radio.Button>
            <Radio.Button value="High">High</Radio.Button>
          </Radio.Group>
        </Form.Item>
        

        <Form.Item
          label="Due date"
          name="due_date"
          rules={[{ required: true, message: "Please input due date!" }]}
        >
          <DatePicker />
        </Form.Item>
        <Form.Item
            label="Frequency"
            name="frequency"
        >
            <Radio.Group>
                <Radio.Button value="Daily">Daily</Radio.Button>
                <Radio.Button value="Weekly">Weekly</Radio.Button>
                <Radio.Button value="Monthly" >Monthly</Radio.Button>
                <Radio.Button value="Annually" >Annually</Radio.Button>
            </Radio.Group>
        </Form.Item>

        <Form.Item
          label="Description"
          name="description"
          rules={[
            { required: true, message: "Please input your description!" },
          ]}

          
        >
          <ReactQuill
                        theme="snow"
                        value={description} // Bind value from state
                        onChange={setDescription} // Update the state on change
                        style={{ height: '150px' }}
                        readOnly={isDescriptionPresent}
                    />
        
          {/* <Input.TextArea rows={10} readOnly={isDescriptionPresent} /> */}
        </Form.Item>
        <Form.Item
          label="Assigned to"
          name="assigned_to"
          rules={[{ required: true, message: "Please input due date!" }]}
        >
          <Select
          mode="multiple"
            options={props?.options?.map((res) => ({
              value: res.id,
              label: `${res.first_name} ${res.last_name}`,
            }))}
          />
        </Form.Item>
        <Form.Item
                    label="Staff Only"
                    name="staff_only_view"
                    rules={[]}
                    valuePropName="checked"
                >
                    <Checkbox />

        </Form.Item>
        {commentBy && commentText && <div>Last Comment:- {commentText} by {commentBy} on {commentDate}</div>}
        <Flex gap={"small"} justify="space-around">
          <Button htmlType="submit" loading={loading} block>
            Update Task
          </Button>
          <Button onClick={() => onFile()} block>
            Your Files
          </Button>
          <Button
            htmlType="button"
            loading={loading}
            onClick={() => commentMode(modalOpen)}
            block
          >
            Comments
          </Button>
          <Button onClick={handleEmails} block>
              Send Mail
            </Button>
          <Button disabled={task_detail?.mail_sent} title={task_detail?.mail_sent ? "Please contact administrator." : "Delete Task"} onClick={()=>deleteTask()} block>
              Delete Task
            </Button>
        </Flex>
      </Form>
    </Modal>
    <FileModal
      title={"YOUR FILES"}
      visible={fileModalVisible}
      setVisible={setFileModalVisible}
      taskId={taskId}
    />
    </>
  );
};

export default TaskModal;
